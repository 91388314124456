var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "portfolio-container" }, [
      _c("div", { staticClass: "portfolio-section" }, [
        _c("div", { staticClass: "portfolio-wrapper" }, [
          _c("div", { staticClass: "section-title" }, [
            _vm._v("\n          PORTFOLIO\n          "),
            _c("div", { staticClass: "toggle-icons" }, [
              _c("img", {
                attrs: {
                  src: "/img/onboarding/direction-left.png",
                  alt: "",
                  width: "25px",
                },
                on: {
                  click: function ($event) {
                    return _vm.previous($event)
                  },
                },
              }),
              _c("img", {
                attrs: {
                  src: "/img/onboarding/direction-right.png",
                  alt: "",
                  width: "25px",
                },
                on: {
                  click: function ($event) {
                    return _vm.next($event)
                  },
                },
              }),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "portfolio-slider",
              attrs: { id: "portfolioCarousel" },
            },
            _vm._l(_vm.selectedTeam.portfolio, function (portfolio, j) {
              return _c("div", { key: j, staticClass: "portfolio-card" }, [
                _c("div", { staticClass: "portfolio-wrap" }, [
                  _c("div", {
                    staticClass: "portfolio-image",
                    style: {
                      backgroundImage: "url(" + portfolio.banner + ")",
                    },
                  }),
                ]),
                _c("div", { staticClass: "portfolio-details" }, [
                  portfolio.project.length > 25
                    ? _c("div", { staticClass: "port-name" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(portfolio.project) +
                            "\n              "
                        ),
                      ])
                    : _c("div", { staticClass: "port-name" }, [
                        _vm._v(_vm._s(portfolio.project)),
                      ]),
                  portfolio.description.length > 114
                    ? _c("div", { staticClass: "port-description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(portfolio.description.substr(0, 114)) +
                            "...\n              "
                        ),
                      ])
                    : _c("div", { staticClass: "port-description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(portfolio.description) +
                            "\n              "
                        ),
                      ]),
                  _c("div", { staticClass: "portfolio-industry" }, [
                    _c("div", { staticClass: "port-div" }, [
                      _vm._v(_vm._s(portfolio.industry)),
                    ]),
                  ]),
                  _c("div", { staticClass: "view-profile" }, [
                    _c(
                      "a",
                      { attrs: { href: portfolio.link, target: "_blank" } },
                      [_vm._v("View Product")]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }