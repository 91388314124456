<template>
  <div>
    <div class="navbar-container">
      <Navbar />
    </div>
    <div key="1" v-if="selectedTeam == ''" class="loader-container">
      <Loader />
    </div>
    <div key="2" v-else class="page-wrap">
      <div class="page-container-fluid">
        <div class="page-navigator">
          <div class="go-back-div" @click="handleGoBack">
            <img src="/img/onboarding/back-icon.svg" />
            <span>Back to View All</span>
          </div>
          <div class="page-pagination">
            <!-- <div class="previous-button">
              <div>
                <img
                  src="/img/onboarding/back-icon.svg"
                  style="margin-right: 9px"
                />
              </div>
              <div class="button-text">Previous Team</div>
            </div>
            <div class="next-button">
              <div class="button-text">Next Team</div>
              <div>
                <img
                  src="/img/onboarding/forward-icon.svg"
                  style="margin-left: 9px"
                />
              </div>
            </div> -->
          </div>
        </div>
        <div class="middle-content">
          <TeamDetails
            v-bind="{
              selectedTeam,
              user,
              spinner,
              percentageMatch,
            }"
          />

          <div
            class="list-wrapper"
            v-if="
              teamInterests(selectedTeam.specialization, selectedTeam.interests)
                .length > 0
            "
          >
            <ChipButton
              v-for="(item, index) in teamInterests(
                selectedTeam.specialization,
                selectedTeam.interests
              )"
              :key="index"
              :text="item"
              :buttonIcon="`/img/interests/${item}.svg`"
            />
          </div>
          <TeamMembers v-bind="{ selectedTeam }" />
          <TeamPortfolio
            v-if="selectedTeam.portfolio.length > 0"
            v-bind="{ selectedTeam }"
          />
          <!-- <TeamReview v-bind="{ selectedTeam }" /> -->
          <div v-if="user.user_type == 2" class="footer-section">
            <div class="footer-innner">
              <div class="like-this">Like this team ?</div>
              <div class="action-btn">
                <button class="grupa-blue-btn" @click="handleContactTeam">
                  Meet and Kickoff
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="call-container">
          <div class="talk-to-grupa">
            Still unsure? <span> Speak with Us</span>
          </div>
          <!-- <div class="page-pagination">
            <div class="previous-button">
              <div>
                <img
                  src="/img/onboarding/back-icon.svg"
                  style="margin-right: 9px"
                />
              </div>
              <div class="button-text">Previous Team</div>
            </div>
            <div class="next-button">
              <div class="button-text">Next Team</div>
              <div>
                <img
                  src="/img/onboarding/forward-icon.svg"
                  style="margin-left: 9px"
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- </transition> -->
    <CalendlyModal
      v-if="showCalendly"
      v-bind:client="true"
      v-on:closeModal="closeCalendly"
      v-bind="{
        loadCalendly,
        user: currentUser,
        meetingName: 'Get Custom Team',
      }"
    />
    <ContactTeamDialog
      v-if="isContactDialog"
      v-bind="{ closeDialog, spinner, handleSaveTeam }"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Navbar from "@/components/Navbar/navbar";
import TeamDetails from "./team_details";
import TeamMembers from "./team_members";
import TeamPortfolio from "./team_portfolio";
import Loader from "@/components/general/loader2";
import { workWithTeam } from "@/api";
import { clientSaveTeam } from "@/api/client";
import CalendlyModal from "@/components/calendlyModal";
import ChipButton from "@/components/chipButton/chipButton";
import ContactTeamDialog from "./contactTeamModal";
import appMixin from "@/mixins/appMixin";

export default {
  mixins: [appMixin],
  props: {},
  components: {
    Navbar,
    TeamDetails,
    TeamMembers,
    TeamPortfolio,
    Loader,
    CalendlyModal,
    ChipButton,
    ContactTeamDialog,
  },
  data: () => ({
    selectedTeam: "",
    spinner: false,
    loadCalendly: true,
    showCalendly: false,
    isContactDialog: false,
  }),

  created() {
    let userName = this.$route.params.teamId;
    this.getSelectedTeam(userName);
  },
  methods: {
    ...mapActions("selectTeamForProject", [
      "updatechosenName",
      "updateSelectedPlan",
    ]),

    getSelectedTeam(userName) {
      workWithTeam(userName)
        .then((response) => {
          this.selectedTeam = response.data.data;
          this.selectedTeam.members = JSON.parse(
            JSON.stringify(response.data.data.members)
          );
          this.$developConsole(response.data, "Work With Team");
        })
        .catch((err) => {
          this.$developConsole("Error Team", err.response);
        });
    },
    switchTeam(teamDetails) {
      // Only client can perform this action

      if (this.user.user_type == 2) {
        const { team } = teamDetails;
        const { user_name } = team;
        team.price ? this.updateSelectedPlan(team.price) : null;
        this.updatechosenName(user_name);
        this.selectedTeam = "";
        this.getSelectedTeam(user_name);
      }
    },
    handleSaveTeam() {
      // Only client can perform this action
      if (this.user.user_type == 2) {
        const teamId = this.selectedTeam.id;
        const project_id = this.$route.params.id;
        this.spinner = true;

        let payload = {
          project_id: project_id,
          team_id: teamId,
          ...this.selectedPlan,
        };

        clientSaveTeam(payload)
          .then((response) => {
            this.spinner = false;
            if (response.data.status == 1) {
              this.$Mixpanel.track("MeetThisTeam", {
                distinct_id: this.currentUser.email,
                team_name: this.selectedTeam.team_name,
              });
            /*   this.$router.push({
                name: "project_workspace",
                params: { id: this.$route.params.id },
              }); */
            }
          })
          .catch((error) => {
            this.$developConsole(error, "An error ......");
          });
      }
    },
    handleGoBack() {
      // Client go to select team
      if (this.user.user_type == 2) {
        this.$router.push({
          name: "select_team",
          params: { id: this.$route.params.id },
        });
      }
      // Team go back to dashboard
      if (this.user.user_type == 1) {
        this.$router.push({ name: "dev_dashboard" });
      }
    },
    closeCalendly() {
      this.showCalendly = false;
    },
    handleCalendlyDialog() {
      // Only client can perform this action
      if (this.user.user_type == 2) {
        showCalendly = true;
      }
    },
    handleContactTeam() {
      this.isContactDialog = true;
    },
    closeDialog() {
      this.isContactDialog = false;
    },
    teamInterests(...value) {
      let finalInterest = [];
      value.map((item) => {
        if (item && item.includes("[")) {
          let removeChar = JSON.parse(item);
          finalInterest = finalInterest.concat(removeChar);
        }
      });
      let teamFrameWorks = this.teamFrameWork(this.selectedTeam.members);
      return finalInterest.concat(teamFrameWorks);
    },
  },
  computed: {
    ...mapState("selectTeamForProject", ["selectedPlan", "availableTeams"]),
    ...mapState("userManagement", ["currentUser"]),
    teams() {
      return this.availableTeams;
    },
    user() {
      return this.currentUser;
    },
    percentageMatch() {
      const teams = this.availableTeams;
      const selectedTeam = this.selectedTeam;

      if (teams && selectedTeam) {
        let findTeam = teams.find((team) => team.team.id == selectedTeam.id);
        return findTeam.percentage;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.navbar-container {
  position: fixed;
  width: 100%;
  z-index: 100;
}
.page-wrap {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f7f7f8;
}
.page-container-fluid {
  /* background-color: #f7f7f8; */
  width: 846px;
}
.page-navigator {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
}
.page-pagination {
  display: flex;
  gap: 32px;
}
.next-button,
.previous-button {
  display: flex;
  cursor: pointer;
}
.button-text {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  margin-top: 3px;
}
.pagination {
  display: flex;
}
.single-page {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  height: 30px;
  cursor: pointer;
}
.current-page {
  background: #53585f;
  border-radius: 2px;
  color: #ffffff;
}
.footer-section {
  padding: 53px 5% 100px 5%;
  border-top: solid 1px #f7f7f8;
}
.footer-innner {
  width: 100%;
}
.action-btn {
  float: right;
}
.schedule-meeting {
  text-align: center;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
  cursor: pointer;
}
.schedule-meeting span {
  color: #0781f2;
  cursor: pointer;
}
.team-badge {
  position: relative;
}
.percentage-matched {
  position: absolute;
  left: 31%;
  top: 27%;
  color: #54cf94;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
}
div.middle-content {
  padding: 48px;
  margin-bottom: 50px;
  width: 846px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 48px;
}

.like-this {
  float: left;
  font-size: 23px;
  line-height: 28px;
  color: #979da5;
}
.container-white {
  background-color: #f7f7f8;
  padding-bottom: 20px;
}
.list-wrapper {
  display: flex;
  margin-top: 48px;
  gap: 12px;
  float: left;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: solid 1px #f7f7f8;
  padding-bottom: 48px;
}
.loader-container {
  margin-top: 80px;
}
.call-container {
  display: flex;
  margin: 48px 0px;
  justify-content: space-between;
}
.talk-to-grupa {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.talk-to-grupa span {
  color: #0781f2;
  margin-left: 4px;
}
</style>
