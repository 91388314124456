var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v("\n        " + _vm._s(_vm.selectedMember.name) + "\n      "),
        ]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "user-info" }, [
          _c("div", { staticClass: "profile-picture" }, [
            _c("div", {
              staticClass: "photo-wrapper",
              style: {
                backgroundImage:
                  "url(" +
                  (_vm.$imageUrl + _vm.selectedMember.profile_picture ||
                    _vm.defaultProfileImage) +
                  ")",
              },
            }),
          ]),
          _c("div", { staticClass: "information" }, [
            _c("div", { staticClass: "member-name" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.selectedMember.name) +
                  "\n          "
              ),
            ]),
            _c("div", { staticClass: "member-role" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.selectedMember.team_role || "No role selected") +
                  "\n            •"
              ),
              _vm.selectedMember.user_location
                ? _c("span", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.selectedMember.user_location)
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm.selectedMember.bio
              ? _c("div", { staticClass: "user-bio" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.truncate(_vm.selectedMember.bio, 230)) +
                      "\n          "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "history-wrapper" },
          [
            _c(
              "VuePerfectScrollbar",
              {
                staticClass: "perfect-scroll-area",
                attrs: { settings: _vm.settings },
              },
              [
                _c("div", { staticClass: "scroll-items" }, [
                  _c("div", { staticClass: "information-box" }, [
                    _c("div", { staticClass: "information-title" }, [
                      _vm._v("EMPLOYMENT"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "information-list" },
                      _vm._l(
                        _vm.selectedMember.work_experiences,
                        function (work) {
                          return _vm.selectedMember.work_experiences.length > 0
                            ? _c(
                                "div",
                                { key: work.id, staticClass: "work-wrapper" },
                                [
                                  work.company_logo
                                    ? _c(
                                        "div",
                                        { staticClass: "company-logo" },
                                        [
                                          _c("div", {
                                            staticClass: "logo-background",
                                            style: {
                                              backgroundImage:
                                                "url(" +
                                                work.company_logo +
                                                ")",
                                            },
                                          }),
                                        ]
                                      )
                                    : _c("div", [
                                        _c("div", {
                                          staticClass: "logo-background",
                                        }),
                                      ]),
                                  _c(
                                    "div",
                                    { staticClass: "employment-details" },
                                    [
                                      _c("div", { staticClass: "work-title" }, [
                                        _vm._v(_vm._s(work.role)),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "company-name" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(work.organization) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      work.current_job
                                        ? _c(
                                            "div",
                                            { staticClass: "work-duration" },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(work.start_date)
                                                      .format("MMM Do YYYY")
                                                  ) +
                                                  "\n                      - till date\n                      " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        work.start_date,
                                                        "YYYYMMDD"
                                                      )
                                                      .fromNow()
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !work.current_job && work.end_date
                                        ? _c(
                                            "div",
                                            { staticClass: "work-duration" },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(work.start_date)
                                                      .format("MMM Do YYYY")
                                                  ) +
                                                  "\n                      - " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(work.end_date)
                                                      .format("MMM Do YYYY")
                                                  ) +
                                                  "\n                      "
                                              ),
                                              work.end_date && work.start_date
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                        .\n                        " +
                                                        _vm._s(
                                                          _vm.dateDifference(
                                                            work.end_date,
                                                            work.start_date
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      work.company_location
                                        ? _c(
                                            "div",
                                            { staticClass: "company-location" },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    work.company_location
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }
                      ),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "information-box" }, [
                    _c("div", { staticClass: "information-title" }, [
                      _vm._v("SKILLS"),
                    ]),
                    _c("div", { staticClass: "information-list" }, [
                      _vm.selectedMember.language_tools
                        ? _c(
                            "div",
                            { staticClass: "user-tools-container" },
                            _vm._l(
                              _vm.parseToJson(
                                _vm.selectedMember.language_tools
                              ),
                              function (stack, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "stack-badge" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(stack) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "information-box" }, [
                    _c("div", { staticClass: "information-title" }, [
                      _vm._v("EDUCATION"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "information-list" },
                      _vm._l(
                        _vm.selectedMember.user_education,
                        function (school, index) {
                          return _vm.selectedMember.user_education.length > 0
                            ? _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "education-container",
                                },
                                [
                                  _c("div", { staticClass: "education-type" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(school.degree_title) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "school-name" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(school.institution) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "education-date" }, [
                                    school.start_date
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm
                                                  .$moment(school.start_date)
                                                  .format("YYYY")
                                              ) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                    -\n                    "
                                    ),
                                    school.end_date
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm
                                                  .$moment(school.end_date)
                                                  .format("YYYY")
                                              ) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            : _vm._e()
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }