<template>
  <div
    class="chip-button"
    :style="
      backgroundColor
        ? `background:${'backgroundColor'}`
        : dashedBorder && selected
        ? 'border: 1px dashed #0781F2;'
        : ''
    "
    :class="{ 'selected-btn': selected, 'dashed-border': dashedBorder }"
    @click="!isDisabled && $emit('click')"
  >
    <div v-if="buttonIcon" class="chip-icon">
      <img :src="buttonIcon" alt="" />
    </div>
    <div class="chip-text" :style="textColor ? `color:${textColor}` : ''">
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
    backgroundColor: {
      type: String,
    },
    textColor: {
      type: String,
    },
    dashedBorder: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {},

  data: () => ({
    isDisabled: false,
  }),

  created() {},
  methods: {},
  computed: {
   
  },
  mounted() {
     this.isDisabled= this.disabled

  }
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.chip-button {
  background: #f7f7f8;
  /* border: 1px dashed #979da5; */
  box-sizing: border-box;
  border-radius: 50px;
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
}
.dashed-border {
  border: 1px dashed #979da5;
}
.active-dashed-border {
  border: 1px dashed #0781f2;
}
.chip-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin-left: 4px;
  margin-top: 1.5px;
}
.selected-btn {
  background: rgba(111, 181, 246, 0.2);
  /* border: 1px dashed #0781f2; */
}
.selected-btn .chip-text {
  color: #0781f2;
}
</style>
