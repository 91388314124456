<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">
          {{ selectedMember.name }}
        </div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="user-info">
          <div class="profile-picture">
            <div
              class="photo-wrapper"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + selectedMember.profile_picture ||
                  defaultProfileImage
                })`,
              }"
            ></div>
          </div>
          <div class="information">
            <div class="member-name">
              {{ selectedMember.name }}
            </div>
            <div class="member-role">
              {{ selectedMember.team_role || "No role selected" }}
              •<span v-if="selectedMember.user_location">
                {{ selectedMember.user_location }}</span
              >
            </div>
            <div class="user-bio" v-if="selectedMember.bio">
              {{ truncate(selectedMember.bio, 230) }}
            </div>
          </div>
        </div>
        <div class="history-wrapper">
          <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
            <div class="scroll-items">
              <div class="information-box">
                <div class="information-title">EMPLOYMENT</div>
                <div class="information-list">
                  <div
                    v-if="selectedMember.work_experiences.length > 0"
                    class="work-wrapper"
                    v-for="work in selectedMember.work_experiences"
                    :key="work.id"
                  >
                  <div v-if="work.company_logo" class="company-logo">
                      <div
                        class="logo-background"
                        :style="{
                          backgroundImage: `url(${work.company_logo})`,
                        }"
                      ></div>
                    </div>
                    <div v-else>
                        <div class="logo-background"></div>
                    </div>
                    <div class="employment-details">
                      <div class="work-title">{{ work.role }}</div>
                      <div class="company-name">
                        {{ work.organization }}
                      </div>
                      <div v-if="work.current_job" class="work-duration">
                        {{ $moment(work.start_date).format("MMM Do YYYY") }}
                        - till date
                        {{ $moment(work.start_date, "YYYYMMDD").fromNow() }}
                      </div>
                      <div
                        v-if="!work.current_job && work.end_date"
                        class="work-duration"
                      >
                        {{ $moment(work.start_date).format("MMM Do YYYY") }}
                        - {{ $moment(work.end_date).format("MMM Do YYYY") }}
                        <span v-if="work.end_date && work.start_date">
                          .
                          {{ dateDifference(work.end_date, work.start_date) }}
                        </span>
                      </div>
                      <div
                        v-if="work.company_location"
                        class="company-location"
                      >
                        {{ work.company_location }}
                      </div>
                    </div>
                
                  </div>
                </div>
              </div>

              <div class="information-box">
                <div class="information-title">SKILLS</div>
                <div class="information-list">
                  <div
                    class="user-tools-container"
                    v-if="selectedMember.language_tools"
                  >
                    <div
                      v-for="(stack, i) in parseToJson(
                        selectedMember.language_tools
                      )"
                      :key="i"
                      class="stack-badge"
                    >
                      {{ stack }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="information-box">
                <div class="information-title">EDUCATION</div>
                <div class="information-list">
                  <div
                    v-if="selectedMember.user_education.length > 0"
                    class="education-container"
                    v-for="(school, index) in selectedMember.user_education"
                    :key="index"
                  >
                    <div class="education-type">
                      {{ school.degree_title }}
                    </div>
                    <div class="school-name">
                      {{ school.institution }}
                    </div>
                    <div class="education-date">
                      <span v-if="school.start_date">
                        {{ $moment(school.start_date).format("YYYY") }}
                      </span>
                      -
                      <span v-if="school.end_date">
                        {{ $moment(school.end_date).format("YYYY") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import truncate from "@/mixins/truncate";
import appMixin from "@/mixins/appMixin";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  mixins: [truncate, appMixin],
  components: {
    VuePerfectScrollbar,
  },
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    selectedMember: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.close-btn {
  cursor: pointer;
}
.dialog-content {
  padding: 24px;
  width: 579px;
}
.user-info {
  display: flex;
  gap: 16px;
}
.profile-picture {
  width: 232px;
  height: 207px;
}
.photo-wrapper {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: top;
}
.information {
  text-align: left;
}
.member-name {
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.member-role {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.member-role span {
  color: #0781f2;
}
.user-bio {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 48px;
  width: 331px;
}
.history-wrapper {
  background: #ffffff;
  box-shadow: inset -1px -1px 1px rgba(187, 187, 187, 0.25),
    inset 1px 1px 1px rgba(187, 187, 187, 0.25);
  border-radius: 5px;
  margin-top: 24px;
}
.scroll-items {
  height: calc(100vh - 334px);
  padding: 24px 16px 24px 24px;
}
.information-box {
  display: flex;
  text-align: left;
  margin-bottom: 38px;
}
.information-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  width: 35%;
}
.information-list {
  width: 65%;
}
.work-wrapper {
  justify-content: space-between;
  display: flex;
  margin-top: 22px;
}
.work-wrapper:nth-of-type(1) {
  margin-top: 0px;
}
.work-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
}
.company-name {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.company-location,
.work-duration {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  width: 100%;
  color: #979da5;
  word-wrap: break-word;
}
.work-duration {
  margin-top: 2px;
}
.company-logo {
  width: 30px;
  height: 30px;
}
.logo-background {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position: top;
}
.user-tools-container {
  display: flex;
  width: 259px;
  flex-wrap: wrap;
}
.stack-badge {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 8px 0px;
  height: 25px;
}
.education-container {
  margin-bottom: 22px;
}
.education-date,
.school-name,
.education-type {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
}
.school-name {
  font-size: 14px;
}
.education-date {
  color: #979da5;
  font-size: 14px;
  margin-top: 2px;
}
.employment-details{
  width: 75%;
  justify-content: center;
  margin-left: 0;
}
</style>