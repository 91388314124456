const defaultPrice = 12500;
const cashAndEquity = 7500;
export const grupaPricing = (compensation, startStage) => {
  switch (true) {
    //   Cash Only
    case compensation == 1:
      return { price: defaultPrice, equity: "0" };
    //   Angel -- Cash + Equity
    case compensation == 2 && startStage == 1:
      return { price: cashAndEquity, equity: "1" };
    //   Angel -- Equity Only
    case compensation == 3 && startStage == 1:
      return { price: 0, equity: "3" };
    //   Pre-seed -- Cash + Equity
    case compensation == 2 && startStage == 2:
      return { price: cashAndEquity, equity: "0.5" };
    //   Pre-seed -- Equity Only
    case compensation == 3 && startStage == 2:
      return { price: 0, equity: "1.5" };
    //   Seed -- Cash + Equity
    case compensation == 2 && startStage == 3:
      return { price: cashAndEquity, equity: "0.25" };
    //   Seed -- Equity Only
    case compensation == 3 && startStage == 3:
      return { price: 0, equity: "0.75" };
    //   Series A -- Cash + Equity
    case compensation == 2 && startStage == 4:
      return { price: cashAndEquity, equity: "0.125" };
    //   Series A -- Equity Only
    case compensation == 3 && startStage == 4:
      return { price: 0, equity: "0.375" };
    // Default Price
    default:
      return { price: defaultPrice, equity: "0" };
  }
};

export const grupaCompensation = (value) => {
  switch (parseInt(value)) {
    case 0:
      return "Cash Only";
    case 1:
      return "Cash & Equity";
    case 2:
      return "Equity only";
    default:
      return "Not Specified";
  }
};
