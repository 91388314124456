var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "team-container" }, [
      _c("div", { staticClass: "team-bio" }, [
        _c("div", { staticClass: "team-name-container" }, [
          _c("div", [
            _c("div", { staticClass: "team-name" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.selectedTeam.team_name
                      ? _vm.selectedTeam.team_name
                      : _vm.selectedTeam.members[0].name.split(" ")[0] +
                          "'s Team"
                  ) +
                  "\n          "
              ),
            ]),
            _c("div", { staticClass: "team-experience" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.selectedTeam.experience
                      ? _vm.selectedTeam.experience.title
                      : ""
                  ) +
                  "\n          "
              ),
            ]),
            _c("div", { staticClass: "rating-wrap" }),
            _c(
              "div",
              {
                staticClass: "team-description",
                staticStyle: { width: "492px" },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.selectedTeam.bio ? _vm.selectedTeam.bio : "") +
                    "\n          "
                ),
              ]
            ),
          ]),
          _c("div", [
            _vm.user.user_type == 2
              ? _c("div", { staticClass: "action-btn" }, [
                  _vm.percentageMatch
                    ? _c("div", { staticClass: "team-badge" }, [
                        _c("div", { staticClass: "percentage-matched" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.percentageMatch) +
                              "%\n                "
                          ),
                          _c("br"),
                          _vm._v("\n                Match\n              "),
                        ]),
                        _c("img", {
                          attrs: {
                            width: "62px",
                            src: "/img/onboarding/percentage-badge.svg",
                          },
                        }),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "team-description" }, [
          _c("div", { staticClass: "others" }, [
            _vm.selectedTeam.country
              ? _c("div", { staticClass: "option" }, [
                  _vm._m(0),
                  _c("span", { staticClass: "open-to" }, [
                    _vm._v(_vm._s(_vm.selectedTeam.country)),
                  ]),
                ])
              : _vm._e(),
            _vm.selectedTeam.timezone
              ? _c("div", { staticClass: "option" }, [
                  _vm._m(1),
                  _c("span", { staticClass: "open-to" }, [
                    _vm._v(
                      "GMT " +
                        _vm._s(_vm.getTimeZone(_vm.selectedTeam.timezone)) +
                        " (" +
                        _vm._s(
                          _vm.compareTimeZone(
                            _vm.selectedTeam.timezone,
                            _vm.userLocation.timezone
                          )
                        ) +
                        ")\n            "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectedTeam.compensation_plans
              ? _c(
                  "div",
                  { staticClass: "option" },
                  [
                    _vm._m(2),
                    _c("span", { staticClass: "open-to" }, [_vm._v("Open to")]),
                    _vm._l(
                      _vm.convertEquity(_vm.selectedTeam.compensation_plans),
                      function (plan, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "compensation-plan" },
                          [_vm._v(_vm._s(plan))]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._m(3),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("img", { attrs: { src: "/img/onboarding/select-team/location.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("img", { attrs: { src: "/img/onboarding/select-team/gmt.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("img", { attrs: { src: "/img/onboarding/stack-of-coins.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "option" }, [
      _c("label", [
        _c("img", { attrs: { src: "/img/onboarding/select-team/time.svg" } }),
      ]),
      _c("span", { staticClass: "open-to" }, [_vm._v("Available to work ")]),
      _c("span", { staticClass: "time-available" }, [
        _vm._v("up to 30h a week"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }