var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chip-button",
      class: {
        "selected-btn": _vm.selected,
        "dashed-border": _vm.dashedBorder,
      },
      style: _vm.backgroundColor
        ? "background:" + "backgroundColor"
        : _vm.dashedBorder && _vm.selected
        ? "border: 1px dashed #0781F2;"
        : "",
      on: {
        click: function ($event) {
          !_vm.isDisabled && _vm.$emit("click")
        },
      },
    },
    [
      _vm.buttonIcon
        ? _c("div", { staticClass: "chip-icon" }, [
            _c("img", { attrs: { src: _vm.buttonIcon, alt: "" } }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "chip-text",
          style: _vm.textColor ? "color:" + _vm.textColor : "",
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }