var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "team-members" }, [
        _c("div", { staticClass: "section-wrap" }, [
          _c("div", { staticClass: "section-title" }, [
            _vm._v(
              "\n        TEAM MEMBERS (" +
                _vm._s(_vm.selectedTeam.members.length) +
                ")\n        "
            ),
            _c("div", { staticClass: "toggle-icons" }, [
              _c("img", {
                attrs: {
                  src: "/img/onboarding/direction-left.png",
                  alt: "",
                  width: "25px",
                },
                on: {
                  click: function ($event) {
                    return _vm.previous($event)
                  },
                },
              }),
              _c("img", {
                attrs: {
                  src: "/img/onboarding/direction-right.png",
                  alt: "",
                  width: "25px",
                },
                on: {
                  click: function ($event) {
                    return _vm.next($event)
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "direction-left" }),
          _c("div", { staticClass: "direction-right" }),
          _c(
            "div",
            { staticClass: "team-wrapper", attrs: { id: "teamCarousel" } },
            _vm._l(_vm.selectedTeam.members, function (member, j) {
              return member.name
                ? _c("div", { key: j, staticClass: "member-card" }, [
                    _c("div", { staticClass: "profile-img-div" }, [
                      _c("div", {
                        staticClass: "profile-banner",
                        style: {
                          backgroundImage:
                            "url(" +
                            (_vm.$imageUrl + member.profile_picture ||
                              _vm.defaultProfileImage) +
                            ")",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "dev-name" }, [
                      _vm._v(_vm._s(member.name)),
                    ]),
                    _c("div", { staticClass: "dev-role" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(member.team_role || "No role selected") +
                          " .\n            "
                      ),
                      member.user_location
                        ? _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(member.user_location) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "dev-work-experience" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            member.years_in_experience
                              ? member.years_in_experience
                              : ""
                          ) +
                          "\n            years work experience\n          "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "dev-company" },
                      [
                        _vm._l(member.work_experiences, function (work, index) {
                          return work.company_logo && index < 3
                            ? _c(
                                "div",
                                { key: work.id, staticClass: "company-logo" },
                                [
                                  _c("div", {
                                    staticClass: "logo-background",
                                    style: {
                                      backgroundImage:
                                        "url(" + work.company_logo + ")",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e()
                        }),
                        member.work_experiences.length > 3
                          ? _c("ChipButton", {
                              attrs: {
                                text:
                                  "+" + (member.work_experiences.length - 3),
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm.parseToJson(member.language_tools).length > 0
                      ? _c(
                          "div",
                          { staticClass: "dev-tools" },
                          _vm._l(
                            _vm.parseToJson(member.language_tools),
                            function (stack, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "stack-div" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(stack) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "view-profile",
                        on: {
                          click: function ($event) {
                            return _vm.handleViewProfile(member)
                          },
                        },
                      },
                      [_vm._v("\n            View Full Profile\n          ")]
                    ),
                  ])
                : _vm._e()
            }),
            0
          ),
        ]),
      ]),
      _vm.isViewModal
        ? _c(
            "ViewProfileModal",
            _vm._b(
              {},
              "ViewProfileModal",
              {
                closeDialog: _vm.closeDialog,
                selectedMember: _vm.selectedMember,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }