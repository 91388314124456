import countryTimeZones from "countries-and-timezones";

export const getTimeZone = (timeZone) => {
  const timezone = countryTimeZones.getTimezone(`${timeZone}`);
  return timezone.dstOffsetStr.split(":")[0];
};

export const compareTimeZone = (timeZone1, timeZone2) => {
  const clientTimeZone = countryTimeZones.getTimezone(`${timeZone2}`);
  let clientGMT = clientTimeZone.utcOffsetStr.split(":")[0];
  clientGMT = parseInt(clientGMT);
  const teamTimeZone = countryTimeZones.getTimezone(`${timeZone1}`);
  let teamGMT = teamTimeZone.utcOffsetStr.split(":")[0];
  teamGMT = parseInt(teamGMT);
  if (clientGMT == teamGMT) {
    return "Same time zone";
  } else {
    let timeDifferences = Math.abs(clientGMT - teamGMT);
    if (clientGMT > teamGMT) {
      return `${timeDifferences}hr${
        timeDifferences > 1 ? "s" : ""
      } behind your location`;
    }
    if (clientGMT < teamGMT) {
      return `${timeDifferences}hr${
        timeDifferences > 1 ? "s" : ""
      } ahead your of location`;
    }
  }
};
