var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "db-workspace docs",
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "padding-top": "100px",
        },
      },
      [
        _c("img", {
          staticStyle: { height: "120px" },
          attrs: { src: "/img/grupa-loader.gif" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }