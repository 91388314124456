<template>
  <div>
    <div class="portfolio-container">
      <div class="portfolio-section">
        <div class="portfolio-wrapper">
          <!-- <div class="direction-left">
            <img
              @click="previous($event)"
              src="/img/onboarding/direction-left.png"
              alt=""
            />
          </div> -->
          <!-- <div class="direction-right">
            <img
              @click="next($event)"
              src="/img/onboarding/direction-right.png"
              alt=""
            />
          </div> -->
          <div class="section-title">
            PORTFOLIO
            <div class="toggle-icons">
              <img
                src="/img/onboarding/direction-left.png"
                @click="previous($event)"
                alt=""
                width="25px"
              />
              <img
                @click="next($event)"
                src="/img/onboarding/direction-right.png"
                alt=""
                width="25px"
              />
            </div>
          </div>
          <div class="portfolio-slider" id="portfolioCarousel">
            <div
              class="portfolio-card"
              v-for="(portfolio, j) in selectedTeam.portfolio"
              :key="j"
            >
              <div class="portfolio-wrap">
                <div
                  class="portfolio-image"
                  :style="{
                    backgroundImage: `url(${portfolio.banner})`,
                  }"
                ></div>
              </div>
              <div class="portfolio-details">
                <div class="port-name" v-if="portfolio.project.length > 25">
                  {{ portfolio.project }}
                </div>
                <div class="port-name" v-else>{{ portfolio.project }}</div>
                <div
                  class="port-description"
                  v-if="portfolio.description.length > 114"
                >
                  {{ portfolio.description.substr(0, 114) }}...
                </div>
                <div class="port-description" v-else>
                  {{ portfolio.description }}
                </div>
                <div class="portfolio-industry">
                  <div class="port-div">{{ portfolio.industry }}</div>
                </div>
                <div class="view-profile">
                  <a :href="portfolio.link" target="_blank">View Product</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedTeam: {
      type: Object,
      required: true,
    },
  },
  components: {},

  data: () => ({
    portfolios: [
      {
        industry: "Lifestyle, Fashion",
        img: "/img/onboarding/company/bg1.png",
      },
      {
        industry: "Finance",
        img: "/img/onboarding/company/bg2.png",
      },
      {
        industry: "Data",
        img: "/img/onboarding/company/bg3.png",
      },
    ],
    frame: 1,
    count: 0,
  }),

  created() {},
  methods: {
    previous(event) {
      event.stopPropagation();
      this.count--;
      this.count = this.count--;
      this.sliderPortfolio("previous");
    },

    next(event) {
      event.stopPropagation();
      this.count++;
      this.count = this.count++;
      this.sliderPortfolio("next");
    },
    sliderPortfolio(position) {
      let el = document.getElementById("portfolioCarousel");
      let pos = 0;
      let id = setInterval(frame, 5);
      let num = this.selectedTeam.portfolio.length - this.frame;
      let width = 400;
      let resize = num * width;
      let check = position == "reset" ? resize : width;
      var obj = this;
      function frame() {
        if (pos == check) {
          clearInterval(id);
        } else {
          pos += 5;
          if (position == "next") {
            el.scrollLeft += 5;
          } else {
            el.scrollLeft -= 5;
          }
        }
      }
    },
  },
  computed: {
    videoLink() {
      const { videos } = this.selectedTeam;
      if (videos.length > 0) {
        const lastVideos = videos[videos.length - 1];
        const uploadedVideo = lastVideos.file_name;
        return this.$videoUrl + uploadedVideo;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.portfolio-container {
  border-top: solid 1px #f7f7f8;
  padding-bottom: 64px;
}
.portfolio-section {
  margin-top: 50px;
  /* display: flex;
  justify-content: space-between; */
}
.section-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
}
.portfolio-wrapper {
  position: relative;
}
.video-container {
  margin-top: 24px;
}
.portfolio-slider {
  /* width: 628px; */
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;
  overflow: hidden;
  gap: 24px;
}
.direction-right {
  position: absolute;
  top: 40%;
  right: -15px;
  z-index: 700;
  cursor: pointer;
}
.direction-left {
  position: absolute;
  top: 40%;
  left: -10px;
  z-index: 700;
  cursor: pointer;
}
/* Portfolio Card */
.portfolio-card {
  background: #ffffff;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 302px;
  text-align: left;
}
.portfolio-details {
  padding: 16px;
}
.portfolio-wrap {
  width: 302px;
  height: 204px;
  border: solid 1px #f7f7f8;
}
.portfolio-image {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-position: top;
}
.portfolio-industry {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.port-div {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 0px 0px;
}
.port-name {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.port-description {
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.view-profile {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  margin-top: 27px;
}
.video-empty-state {
  background: #f7f7f8;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 519px;
  height: 405px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video-empty-text {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #c8cbd0;
  margin-top: 8px;
}
.toggle-icons {
  float: right;
}
</style>
