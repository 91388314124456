var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "navbar-container" }, [_c("Navbar")], 1),
      _vm.selectedTeam == ""
        ? _c(
            "div",
            { key: "1", staticClass: "loader-container" },
            [_c("Loader")],
            1
          )
        : _c("div", { key: "2", staticClass: "page-wrap" }, [
            _c("div", { staticClass: "page-container-fluid" }, [
              _c("div", { staticClass: "page-navigator" }, [
                _c(
                  "div",
                  {
                    staticClass: "go-back-div",
                    on: { click: _vm.handleGoBack },
                  },
                  [
                    _c("img", {
                      attrs: { src: "/img/onboarding/back-icon.svg" },
                    }),
                    _c("span", [_vm._v("Back to View All")]),
                  ]
                ),
                _c("div", { staticClass: "page-pagination" }),
              ]),
              _c(
                "div",
                { staticClass: "middle-content" },
                [
                  _c(
                    "TeamDetails",
                    _vm._b(
                      {},
                      "TeamDetails",
                      {
                        selectedTeam: _vm.selectedTeam,
                        user: _vm.user,
                        spinner: _vm.spinner,
                        percentageMatch: _vm.percentageMatch,
                      },
                      false
                    )
                  ),
                  _vm.teamInterests(
                    _vm.selectedTeam.specialization,
                    _vm.selectedTeam.interests
                  ).length > 0
                    ? _c(
                        "div",
                        { staticClass: "list-wrapper" },
                        _vm._l(
                          _vm.teamInterests(
                            _vm.selectedTeam.specialization,
                            _vm.selectedTeam.interests
                          ),
                          function (item, index) {
                            return _c("ChipButton", {
                              key: index,
                              attrs: {
                                text: item,
                                buttonIcon: "/img/interests/" + item + ".svg",
                              },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "TeamMembers",
                    _vm._b(
                      {},
                      "TeamMembers",
                      { selectedTeam: _vm.selectedTeam },
                      false
                    )
                  ),
                  _vm.selectedTeam.portfolio.length > 0
                    ? _c(
                        "TeamPortfolio",
                        _vm._b(
                          {},
                          "TeamPortfolio",
                          { selectedTeam: _vm.selectedTeam },
                          false
                        )
                      )
                    : _vm._e(),
                  _vm.user.user_type == 2
                    ? _c("div", { staticClass: "footer-section" }, [
                        _c("div", { staticClass: "footer-innner" }, [
                          _c("div", { staticClass: "like-this" }, [
                            _vm._v("Like this team ?"),
                          ]),
                          _c("div", { staticClass: "action-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn",
                                on: { click: _vm.handleContactTeam },
                              },
                              [
                                _vm._v(
                                  "\n                Meet and Kickoff\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._m(0),
            ]),
          ]),
      _vm.showCalendly
        ? _c(
            "CalendlyModal",
            _vm._b(
              {
                attrs: { client: true },
                on: { closeModal: _vm.closeCalendly },
              },
              "CalendlyModal",
              {
                loadCalendly: _vm.loadCalendly,
                user: _vm.currentUser,
                meetingName: "Get Custom Team",
              },
              false
            )
          )
        : _vm._e(),
      _vm.isContactDialog
        ? _c(
            "ContactTeamDialog",
            _vm._b(
              {},
              "ContactTeamDialog",
              {
                closeDialog: _vm.closeDialog,
                spinner: _vm.spinner,
                handleSaveTeam: _vm.handleSaveTeam,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "call-container" }, [
      _c("div", { staticClass: "talk-to-grupa" }, [
        _vm._v("\n          Still unsure? "),
        _c("span", [_vm._v(" Speak with Us")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }