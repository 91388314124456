<template>
  <div>
    <div class="team-container">
      <div class="team-bio">
        <div class="team-name-container">
          <div>
            <div class="team-name">
              {{
                selectedTeam.team_name
                  ? selectedTeam.team_name
                  : `${selectedTeam.members[0].name.split(" ")[0]}'s Team`
              }}
            </div>
            <div class="team-experience">
              {{ selectedTeam.experience ? selectedTeam.experience.title : "" }}
            </div>
            <div class="rating-wrap">
              <!-- <div class="team-rating">{{ selectedTeam.grupa_score }}.0 ⭐</div>
          <div class="team-speciality">
            <img src="/img/onboarding/speciality-icon.svg" />
            {{
              selectedTeam.specialization
                ? checkUnderscore(selectedTeam.specialization)
                : ""
            }}
          </div> -->
              <!-- <div class="team-location">
            <img src="/img/onboarding/location-icon.svg" />
            {{ selectedTeam.city ? selectedTeam.city + "," : "" }}
            {{ selectedTeam.country ? selectedTeam.country : " " }}
          </div> -->
            </div>
            <div class="team-description" style="width: 492px">
              {{ selectedTeam.bio ? selectedTeam.bio : "" }}
            </div>
          </div>
          <div>
            <div class="action-btn" v-if="user.user_type == 2">
              <div v-if="percentageMatch" class="team-badge">
                <div class="percentage-matched">
                  {{ percentageMatch }}%
                  <br />
                  Match
                </div>
                <img width="62px" src="/img/onboarding/percentage-badge.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="team-description">
          <div class="others">
            <div v-if="selectedTeam.country" class="option">
              <label
                ><img src="/img/onboarding/select-team/location.svg"
              /></label>
              <span class="open-to">{{ selectedTeam.country }}</span>
            </div>
            <div v-if="selectedTeam.timezone" class="option">
              <label><img src="/img/onboarding/select-team/gmt.svg" /></label>
              <span class="open-to"
                >GMT {{ getTimeZone(selectedTeam.timezone) }} ({{
                  compareTimeZone(selectedTeam.timezone, userLocation.timezone)
                }})
              </span>
            </div>
            <div v-if="selectedTeam.compensation_plans" class="option">
              <label><img src="/img/onboarding/stack-of-coins.svg" /></label>
              <span class="open-to">Open to</span>
              <span
                class="compensation-plan"
                v-for="(plan, index) in convertEquity(
                  selectedTeam.compensation_plans
                )"
                :key="index"
                >{{ plan }}</span
              >
            </div>
            <div class="option">
              <label><img src="/img/onboarding/select-team/time.svg" /></label>
              <span class="open-to">Available to work </span>
              <span class="time-available">up to 30h a week</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { grupaCompensation } from "@/utils/grupaVariables";
import { compareTimeZone, getTimeZone } from "@/utils/timeZone";
import shortName from "@/mixins/shortName";

export default {
  props: {
    selectedTeam: {
      type: Object,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    percentageMatch: {
      required: true,
    },
  },
  mixins: [shortName],
  components: {},

  data: () => ({
    userRegion: {},
    compareTimeZone: compareTimeZone,
    getTimeZone: getTimeZone,
  }),

  created() {},
  methods: {
    convertEquity(value) {
      if (value && value.includes("[")) {
        let toArray = JSON.parse(value);
        return toArray.map((item) => grupaCompensation(item));
      }
      return [];
    },
  },
  computed: {
    ...mapState("userManagement", ["userLocation", "currentUser"]),
  },
};
</script>
<style scoped>
.team-container {
}
.team-bio {
  text-align: left;
  /* width: 628px; */
}
.team-name {
  color: #1b1e22;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
}
.rating-wrap {
  display: flex;
  justify-content: space-between;
  /* margin-top: 64px; */
  width: 504px;
}
.team-experience {
  font-size: 16px;
  line-height: 130%;
  color: #979da5;
}
.team-rating,
.team-speciality {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.team-location {
  color: #0781f2;
}
.team-description {
  margin-top: 24px;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.team-badge {
  position: relative;
}
.percentage-matched {
  position: absolute;
  left: 25%;
  top: 24%;
  color: #54cf94;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
}
div.others {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  row-gap: 12px;
  margin-bottom: 48px;
}
.open-to {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
div.others .option {
  text-overflow: wrap;
}
.option label {
  margin-right: 4px;
}
.compensation-plan {
  color: #de9221;
  margin-left: 4px;
}
.team-name-container {
  display: flex;
  justify-content: space-between;
}
.time-available {
  color: #1fa564;
}
</style>
