<template>
  <div>
    <div class="team-members">
      <div class="section-wrap">
        <div class="section-title">
          TEAM MEMBERS ({{ selectedTeam.members.length }})
          <div class="toggle-icons">
            <img
              src="/img/onboarding/direction-left.png"
              @click="previous($event)"
              alt=""
              width="25px"
            />
            <img
              @click="next($event)"
              src="/img/onboarding/direction-right.png"
              alt=""
              width="25px"
            />
          </div>
        </div>

        <div class="direction-left"></div>
        <div class="direction-right"></div>
        <div class="team-wrapper" id="teamCarousel">
          <div
            class="member-card"
            v-for="(member, j) in selectedTeam.members"
            :key="j"
            v-if="member.name"
          >
            <div class="profile-img-div">
              <div
                class="profile-banner"
                :style="{
                  backgroundImage: `url(${
                    $imageUrl + member.profile_picture || defaultProfileImage
                  })`,
                }"
              ></div>
            </div>
            <div class="dev-name">{{ member.name }}</div>
            <div class="dev-role">
              {{ member.team_role || "No role selected" }} .
              <span v-if="member.user_location">
                {{ member.user_location }}
              </span>
            </div>
            <div class="dev-work-experience">
              {{ member.years_in_experience ? member.years_in_experience : "" }}
              years work experience
            </div>
            <div class="dev-company">
              <div
                class="company-logo"
                v-for="(work, index) in member.work_experiences"
                :key="work.id"
                v-if="work.company_logo && index < 3"
              >
                <div
                  class="logo-background"
                  :style="{
                    backgroundImage: `url(${work.company_logo})`,
                  }"
                ></div>
              </div>
              <ChipButton
                v-if="member.work_experiences.length > 3"
                :text="`+${member.work_experiences.length - 3}`"
              />
            </div>
            <div
              class="dev-tools"
              v-if="parseToJson(member.language_tools).length > 0"
            >
              <div
                v-for="(stack, i) in parseToJson(member.language_tools)"
                :key="i"
                class="stack-div"
              >
                {{ stack }}
              </div>
            </div>
            <div @click="handleViewProfile(member)" class="view-profile">
              View Full Profile
            </div>
          </div>
        </div>
      </div>
    </div>
    <ViewProfileModal
      v-if="isViewModal"
      v-bind="{ closeDialog, selectedMember }"
    />
  </div>
</template>

<script>
import appMixin from "@/mixins/appMixin";
import ViewProfileModal from "./profileModal.vue";
import ChipButton from "@/components/chipButton/chipButton";
export default {
  props: {
    selectedTeam: {
      type: Object,
    },
  },
  components: {
    ViewProfileModal,
    ChipButton,
  },
  mixins: [appMixin],
  data: () => ({
    frame: 3,
    count: 0,
    isViewModal: false,
    selectedMember: null,
  }),

  created() {},
  methods: {
    previous(event) {
      event.stopPropagation();
      this.count--;
      this.count = this.count--;
      this.sliderPortfolio("previous");
    },

    next(event) {
      event.stopPropagation();
      this.count++;
      this.count = this.count++;
      this.sliderPortfolio("next");
    },
    sliderPortfolio(position) {
      let el = document.getElementById("teamCarousel");
      let pos = 0;
      let id = setInterval(frame, 5);
      let num = this.selectedTeam.members.length - this.frame;
      let width = 400;
      let resize = num * width;
      let check = position == "reset" ? resize : width;
      var obj = this;
      function frame() {
        if (pos == check) {
          clearInterval(id);
        } else {
          pos += 5;
          if (position == "next") {
            el.scrollLeft += 5;
          } else {
            el.scrollLeft -= 5;
          }
          //  obj.checkScrolLeft(el.scrollLeft);
        }
      }
    },
    handleViewProfile(member) {
      this.selectedMember = member;
      this.isViewModal = true;
    },
    closeDialog() {
      this.isViewModal = false;
    },
  },
  computed: {},
};
</script>
<style scoped>
.team-members {
  border-top: solid 1px #f7f7f8;
  padding-bottom: 64px;
  /* margin-top: 48px; */
}
.section-wrap {
  margin-top: 170px;
  position: relative;
}
.section-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
}
.team-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 24px;
  overflow: hidden;
  gap: 24px;
}
/* Member card style */
.member-card {
  width: 231px;
  text-align: left;
  /* margin-right: 32px; */
}
.profile-img-div {
  width: 231px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 212px;
}
.profile-banner {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: top;
}
.dev-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 16px;
}
.dev-role {
  margin-top: 2px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.dev-role span {
  color: #0781f2;
}
.dev-work-experience {
  margin-top: 24px;
  font-size: 12px;
  line-height: 140%;
  color: #1fa564;
}
.dev-company {
  margin-top: 6px;
  display: flex;
}
.dev-tools {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  min-height: 68px;
}
.stack-div {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 8px 0px;
  height: 25px;
}
.view-profile {
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
.direction-right {
  position: absolute;
  top: 32%;
  right: -21px;
  z-index: 700;
  cursor: pointer;
}
.direction-left {
  position: absolute;
  top: 32%;
  left: -21px;
  z-index: 700;
  cursor: pointer;
}
.company-logo {
  width: 39px;
  height: 32px;
}
.logo-background {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position: top;
}
.toggle-icons {
  float: right;
}
</style>
